// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
$primary-color: var(--ion-color-primary);
$primary-color-contrast: var(--ion-color-primary-contrast);
$secondary-color: var(--ion-color-dark);
$secondary-color-contrast: var(--ion-color-secondary-contrast);
$font-family: var(--ion-font-family);

// Font downloaded from https://fonts.google.com/specimen/Poppins
@font-face {
  font-family: 'Poppins-Light';
  src: url('../fonts/Poppins/Poppins-Light.ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-Regular';
  src: url(../fonts/Poppins/Poppins-Regular.ttf);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-Medium';
  src: url('../fonts/Poppins/Poppins-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

:root {
  /** primary **/
  --ion-color-primary: #89B2AA;
  --ion-color-primary-rgb: 137, 178, 170;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #607D77;
  --ion-color-primary-tint: #DCE8E6;
  --ion-color-primary-medium-tint: #ACC9C4;

  /** secondary **/
  --ion-color-secondary: #0544BB;
  --ion-color-secondary-rgb: 5, 68, 187;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #043083;
  --ion-color-secondary-tint: #9BB4E4;

  /** tertiary **/
  --ion-color-tertiary: #018287;
  --ion-color-tertiary-rgb: rgb(1, 130, 135);
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #014E51;
  --ion-color-tertiary-tint: #67B4B7;

  /** success **/
  --ion-color-success: #89B2AA;
  --ion-color-success-rgb: 137, 178, 170;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #6E8E88;
  --ion-color-success-tint: #ACC9C4;

  /** warning **/
  --ion-color-warning: #FFC546;
  --ion-color-warning-rgb: 255, 197, 70;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #E6B13F;
  --ion-color-warning-tint: #FFDC90;

  /** danger **/
  --ion-color-danger: #D55E00;
  --ion-color-danger-rgb: 213, 94, 0;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #AA4B00;
  --ion-color-danger-tint: #E28E4D;

  /** light **/
  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 255, 255, 255;
  --ion-color-light-contrast: #000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #EEF1F6;
  --ion-color-light-tint: #F9FCFF;

  /** medium **/
  --ion-color-medium: #AFBFD6;
  --ion-color-medium-rgb: 175, 191, 214;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #8291a7;
  --ion-color-medium-tint: #D5DDE9;

  /** dark **/
  --ion-color-dark: #323841;
  --ion-color-dark-rgb: 50, 56, 65;
  --ion-color-dark-contrast: #fff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #121212;
  --ion-color-dark-tint: #464E5B;

  /* consumption */
  --ion-color-consumption: #AE7EEC;
  --ion-color-consumption-rgb: 174, 126, 236;
  --ion-color-consumption-shade: #8B65BD;
  --ion-color-consumption-tint: #C6A5F2;

  /* discharge */
  --ion-color-discharge: #56B4E9;
  --ion-color-discharge-rgb: rgb(86, 180, 233);

  /** font **/
  --ion-font-family: "Poppins-Regular", "Arial", sans-serif;
  --font-family-light: "Poppins-Light";
  --font-family-regular: "Poppins-Regular";
  --font-family-medium: "Poppins-Medium";

  --text-color-dark: black;
  --text-color-medium: var(--ion-color-medium-shade);
  --text-color-light: #ffff;
  --ion-text-color: var(--text-color-dark);
  --ion-text-color-rgb: var(--text-color-dark);  
  
  --ion-color-background: #fff;
  --default-border-radius: 5px;
  --max-page-content-width: 1600px;
}

/** Custom Colors **/
:root {
  --ion-color-environmental: #8abd46;
  --ion-color-environmental-rgb: 138, 189, 70;
  --ion-color-environmental-contrast: #fff;
  --ion-color-environmental-contrast-rgb: 255, 255, 255;
  --ion-color-environmental-shade: #527029;
  --ion-color-environmental-tint: #bbff5d;

  .ion-color-environmental {
    --ion-color-base: var(--ion-color-environmental);
    --ion-color-base-rgb: var(--ion-color-environmental-rgb);
    --ion-color-contrast: var(--ion-color-environmental-contrast);
    --ion-color-contrast-rgb: var(--ion-color-environmental-contrast-rgb);
    --ion-color-shade: var(--ion-color-environmental-shade);
    --ion-color-tint: var(--ion-color-environmental-tint);
  }

  --ion-color-production: var(--ion-color-warning-shade);
  --ion-color-production-rgb: 230, 177, 63;
  --ion-color-production-contrast: #fff;
  --ion-color-production-contrast-rgb: 255, 255, 255;
  --ion-color-production-shade: #226e84;
  --ion-color-production-tint: #41d4ff;

  .ion-color-production {
    --ion-color-base: var(--ion-color-production);
    --ion-color-base-rgb: var(--ion-color-production-rgb);
    --ion-color-contrast: var(--ion-color-production-contrast);
    --ion-color-contrast-rgb: var(--ion-color-production-contrast-rgb);
    --ion-color-shade: var(--ion-color-production-shade);
    --ion-color-tint: var(--ion-color-production-tint);
  }
}

.fullHeight {
  --height: 100%;
}

h2, .text-heading2 {
  font-family: "Poppins-Light";
  font-size: 58px;
}

h3, .text-heading3 {
  font-family: "Poppins-Light";
  font-size: 47px;
}

h4, .text-heading4 {
  font-family: "Poppins-Light";
  font-size: 33px;
}

h5, .text-heading5 {
  font-family: "Poppins-Light";
  font-size: 22px;
}

h6, .text-heading6 {
  font-family: "Poppins-Medium";
  font-weight: 500;
  font-size: 16px;
}

.text-subtitle1 {
  font-family: "Poppins-Medium";
  font-size: 14px;
}

body, .text-body {
  font-family: "Poppins-Light";
  font-size: 14px;
}

.color-dark {
  --color: var(--text-color-dark);
  color: var(--text-color-dark);
}

.color-medium {
  --color: var(--text-color-medium);
  color: var(--text-color-medium);
}

.color-light {
  --color: var(--text-color-light);
  color: var(--text-color-light);
}

ion-button {
  --box-shadow: none;
  border-radius: var(--default-border-radius);
  font-family: "Poppins-Medium";
  text-transform: none;
  letter-spacing: 0.05rem;
}

.button-primary {
  --background: var(--ion-color-primary);
  --background-hover: var(--ion-color-primary-tint);
  --background-hover-opacity: 1;
  --color: var(--text-color-dark);
}

.button-secondary {
  --background: transparent;
  --background-hover: var(--ion-color-medium-tint);
  --background-hover-opacity: 0.5;
  --color: var(--text-color-dark);
  --border-style: solid;
  --border-width: 1px;
  --border-radius: var(--default-border-radius);
  height: 2.5rem;

  &.dark {
    --color: var(--text-color-light);
    --background-hover: var(--ion-color-medium-shade) !important;
  }
}

.button-tertiary {
  --background: transparent;
  --background-hover-opacity: 0;
  --background-activated-opacity: 0;

  &:hover {
    text-decoration: underline;
  }
}

.button-navbar {
  --background: transparent;
  background: transparent;
  --color: var(--text-color-light);
  color: var(--text-color-light);
  border-radius: var(--default-border-radius);
  
  &.active {
    --background: var(--ion-color-primary-tint);
    background: var(--ion-color-primary-tint);
    --color: var(--text-color-dark);
    color: var(--text-color-dark);
  }

  &:hover {
    --background: var(--ion-color-primary-tint);
    background: var(--ion-color-primary-tint);
    --color: var(--text-color-dark);
    color: var(--text-color-dark);
  }
}

ion-menu {
  --ion-background-color: var(--ion-color-dark);
  --ion-text-color: #ffff;
}

.background-gradient {
  // Apply to the root ion-content element of every page (live, history etc.)
  ion-content {
    --background: 
    radial-gradient(circle at 70% 100%, var(--ion-color-primary-tint), transparent),
    radial-gradient(circle at 90% 100%, var(--ion-color-medium-shade), transparent 80%);
  }
}

ion-modal {
  --border-radius: var(--default-border-radius);
}

ion-toggle {
  --background: var(--ion-color-medium-shade);
}